import React, {Component} from 'react';

import {TableCell} from '@material-ui/core';

class myCell extends Component {
    render() {
        if (typeof this.props.column.renderContent !== 'undefined') {
            return (
                <TableCell>
                    {this.props.column.renderContent(this.props.row, this.props.column)}
                </TableCell>
            );
        }
        return (
            <TableCell>
                {this.props.row[this.props.column.id]}
            </TableCell>
        );
    }
}

export default myCell;