import React, {Component} from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import {withRouter} from 'react-router-dom';

class navigationItem extends Component {
    render() {

        return (
            <ListItem button onClick={() => this.props.clicked(this.props.page)} selected={this.props.active}>
                <ListItemIcon>{this.props.page.icon}</ListItemIcon>
                <ListItemText>{this.props.page.title}</ListItemText>
            </ListItem>
        );
    }
}

export default withRouter(navigationItem);