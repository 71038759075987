import React, {Component} from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    TextField,
    Switch
} from '@material-ui/core';

class orderSummary extends Component {
    orderNow = () => {
        if(this.props.orderDescription !== ''){
            this.props.handleClose('ordered')
        }
    };

    render() {
        const output = {};
        for (let key in this.props.orderPositions) {
            const orderPosition = this.props.orderPositions[key];
            if (typeof output[orderPosition.distributor] === 'undefined') {
                output[orderPosition.distributor] = [];
            }
            output[orderPosition.distributor].push(
                <li key={key}>{orderPosition.quantity + 'x ' + orderPosition.distributorPartNumber}</li>
            );
        }


        return (
            <Dialog open={this.props.open} onClose={() => this.props.handleClose('canceled')}>
                <DialogTitle>Bestellübersicht</DialogTitle>
                <DialogContent>
                    {Object.keys(output).map(key => (
                        <React.Fragment key={key}>
                            <Typography><b>{'Folgende Positionen werden bei ' + key + ' bestellt:'}</b></Typography>
                            <ul><Typography>{output[key]}</Typography></ul>
                        </React.Fragment>
                    ))}
                    <Typography><b>Bezeichnung deiner Bestellung:</b></Typography>
                    <TextField
                        fullWidth
                        label="Bestellbezeichnung"
                        value={this.props.orderDescription}
                        onChange={(event) => this.props.orderDescriptionChanged(event.target.value)}
                        error={this.props.orderDescription === ''}
                    />
                    <Typography style={{marginTop: '10px'}}><b>Private Bestellung?</b></Typography>
                    <Switch
                        color="primary"
                        ckecked={this.props.privateOrder ? 'true' : 'false'}
                        onChange={(event) => this.props.privateChanged(event.target.checked)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.handleClose('canceled')} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={this.orderNow} color="primary">
                        Jetzt bestellen
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default orderSummary;