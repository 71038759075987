import React, {Component} from 'react';
import Drawer from './Navigation/Drawer/Drawer';
import Toolbar from './Navigation/Toolbar/Toolbar';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom'

const styles = theme => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        margin: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexFlow: 'column',
        height: 'calc(100vh - 40px)',
    }
});

class Layout extends Component {
    state = {
        mobileDrawerOpen: false,
    };


    drawerToggleHandler = () => {
        this.setState((previousState) => {
            return {mobileDrawerOpen: !previousState.mobileDrawerOpen};
        });
    };

    navigationClickedHandler = (page) =>{
        this.props.history.push(page.link);
        this.setState({
            mobileDrawerOpen: false,
        });
    }

    render() {
        let activePage;
        this.props.pages.forEach(page => {
            if(this.props.location.pathname === page.link){
                activePage = page;
            }
        })
        return (
            <React.Fragment>
                <Toolbar title={activePage ? activePage.title : "Not found"} drawerOpen={this.drawerToggleHandler}/>
                <Drawer
                    show={this.state.mobileDrawerOpen}
                    drawerClose={this.drawerToggleHandler}
                    itemClicked={this.navigationClickedHandler}
                    pages={this.props.pages}
                    activePage={activePage}
                    userInfo={this.props.userInfo}
                />
                <main className={this.props.classes.content}>
                    <div className={this.props.classes.toolbar}/>
                    {this.props.children}
                </main>
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(Layout));