import React, {Component} from 'react';

import Typography from '@material-ui/core/Typography';
import {Button} from '@material-ui/core';

class userInfo extends Component{

    render(){

        return(
            <div>
                <Typography variant="h6">
                    {this.props.name}
                </Typography>
                <Button onClick={this.props.logout}>
                    Logout
                </Button>
            </div>

        );
    }
}

export default userInfo;