import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import {withStyles} from '@material-ui/core/styles';

import NavigationItem from './NavigationItem/NavigationItem';


const drawerWidth = 240;

const styles = theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    }
});

class ResponsiveDrawer extends Component {

    render() {
        const {classes} = this.props;

        const navigationItems = this.props.pages.map(page => {
            return (
                <NavigationItem
                    page={page}
                    clicked={this.props.itemClicked}
                    key={page.link}
                    active={this.props.activePage === page}
                />
            );
        });

        const drawer = (
            <div>
                {this.props.userInfo}
                {/*<div className={classes.toolbar}/>*/}
                <Divider/>
                <List>
                    {navigationItems}
                </List>
            </div>
        );

        return (
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        open={this.props.show}
                        onClose={this.props.drawerClose}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(ResponsiveDrawer));