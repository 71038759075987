import React, {Component} from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions
} from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/firestore';

import MyTable from '../UI/MyTable/MyTable';
import OrderItemOverview from '../OrderItemOverview/OrderItemOverview';
import {Block as BlockIcon} from '@material-ui/icons';

class order extends Component {
    constructor(props) {
        super(props);
        this.state = {items: {}, rows: [], distributor: null, selectedItems: {}, orderItems: null};
        this.columns = [
            {id: 'user', label: 'Besteller'},
            {id: 'project', label: 'Projekt'},
            {id: 'distributorPartNumber', label: 'Bestellnummer'},
            {id: 'quantity', label: 'Anzahl'},
            {
                id: 'pricePerUnit',
                label: 'Preis/Stk.',
                renderContent: (row, column) => parseFloat(row[column.id]).toFixed(2)
            },
            {
                id: 'action',
                label: 'Aktion',
                renderContent: (row, column) => (
                    <Button
                        variant="contained"
                        color='secondary'
                        startIcon={<BlockIcon/>}
                        onClick={event => {
                            event.stopPropagation();
                            this.cancelOrderItems([row.id]);
                        }}
                    >
                        Stoppen
                    </Button>
                )
            }
        ];
    }

    fetchOrderItems = distributor => {
        this.orderItemsUnsubscribe = firebase.firestore().collection('orderItems')
            .where('state', '==', 'Unterschrieben')
            .where('distributor', '==', distributor)
            .orderBy('distributorPartNumber')
            .onSnapshot(querySnapshot => {
                const items = {};
                querySnapshot.forEach(doc => {
                    items[doc.id] = doc.data();
                    items[doc.id].user = this.props.users[doc.data().user]?.first_name + ' ' +
                        this.props.users[doc.data().user]?.last_name;
                });
                const rows = Object.keys(items).map(key => {
                    const row = {};
                    this.columns.forEach(column => {
                        row[column.id] = items[key][column.id];
                    });
                    row.id = key;

                    items[key].history?.forEach(event => {
                        event.user = this.props.users[event.user]?.first_name + ' ' +
                            this.props.users[event.user]?.last_name;
                    });
                    row.extendedContent = (
                        <OrderItemOverview orderItem={items[key]}/>
                    );
                    return row;
                });
                this.setState({items: items, rows: rows, distributor: distributor});
            });
    };

    cancelOrderItems = orderItems => {
        orderItems.forEach(id => {
            firebase.firestore().collection('orderItems').doc(id).update({
                state: 'Gestoppt',
                updatedBy: this.props.user.uid
            });
        });
    };

    componentWillUnmount() {
        if (typeof this.orderItemsUnsubscribe === 'function') {
            this?.orderItemsUnsubscribe();
        }
    }

    selectionChanged = (selectedItemIDs) => {
        const selectedItems = {};
        selectedItemIDs.forEach(id => {
            selectedItems[id] = this.state.items[id];
        });
        this.setState({selectedItems: selectedItems});
    };

    order = items => {
        this.setState({orderItems: items});
    };

    orderedSelected = () => {
        
        const items = {...this.state.orderItems};
        
        const currentUser = this.props.user.uid;
        // Array zur Zwischenspeicherung aller Benutzer, die Artikel bestellt haben.
        let users = [];
        // Array für alle bestellten Artikel.
        let articles = [];
        
        // Das Mail soll erst generiert und versendet werden, wenn alle soeben versendeten Artikel
        // zusammengetragen worden sind. Zu diesem Zweck wird ein Promise verwendet.
        // then() wird ausgeführt, wenn resolve() aufgerufen wird.
        new Promise( function(resolve) {
            
            // Alle bestellten Artikel von der Datenbank abfragen.
            for (let itemKey in items) {
                
                // Dokument von DB abfragen.
                let docRef = firebase.firestore().collection('orderItems').doc(itemKey);
                
                // Status des bestellten Artikels auf "Bestellt" ändern.
                docRef.update({
                    state: 'Bestellt',
                    updatedBy: currentUser
                    
                }).then( () => {
                
                    // Bestellter Artikel zwischenspeichern, um dem Besteller später eine Mail zu senden.
                    docRef.onSnapshot( snapshot => {
                        
                        let item = snapshot.data();
                        
                        // Jeder bestellende User wird einmalig im users Array gespeichert.
                        // Für jeden User im Array wird später eine E-Mail versendet.
                        if ( !users.includes(item['user']) ) {
                            users.push( item['user'] );
                        }

                        // Jeder bestellte Artikel wird im Array articles gespeichert, damit später
                        // eine E-Mail mit der zusammengefassten Bestellung versendet werden kann.
                        articles.push({
                            user:                  item['user'],
                            quantity:              item['quantity'],
                            distributor:           item['distributor'],
                            distributorPartNumber: item['distributorPartNumber'],
                            description:           item['description'],
                            project:               item['project']
                        });
                        
                        // Enthält das Array articles soviele Elemente wie bestellte Artikel,
                        // kann resolve() ausgeführt werden. Damit ist das Versprechen (Promise) erfüllt
                        // und die E-Mail kann in then(...) generiert werden.
                        if (articles.length >= Object.keys(items).length) {
                            resolve();
                        }
                    });
                
                });
            }
        }).then( () => {

            // Für jeden Benutzer wird nun eine E-Mail mit seinen bestellten Artikeln erstellt.
            users.forEach( (usr) => {

                let mailSubject; // E-Mail Betreff
                let mailContent; // E-Mail Inhalt (HTML)
                
                // Betreff erstellen.
                mailSubject = 'Artikel bei ' + articles[0]['distributor'] + ' bestellt';
                
                // HTML-Inhalt erstellen. Hier wird eine Tabelle vorbereitet.
                mailContent =
                        '<h3>Artikel bei ' + articles[0]['distributor'] + ' bestellt</h3>' +
                        '<p>Hallo ' + this.props.users[usr]?.first_name + '</p>' +
                        '<p>Folgende Artikel von ' + articles[0]['distributor'] + ' wurden bestellt.</p>' +
                        '<table style="border: none;">' + 
                        '<tr>' +
                            '<td style="padding-right: 10px; font-weight: bold;">Art.-Nr.</td>' +
                            '<td style="padding-right: 10px; font-weight: bold;">Anzahl</td>' +
                            '<td style="padding-right: 10px; font-weight: bold;">Beschreibung</td>' +
                            '<td style="padding-right: 10px; font-weight: bold;">Projekt</td>' +
                        '</tr>';
               
               // Für jeden bestellten Artikel wird eine Tabellen-Zeile erstellt,
               // sofern es ein Artikel des Benutzers ist, dessen E-Mail wir gerade generieren.
                articles.forEach( (article) => {
                    if ( article['user'] === usr ) {
                        mailContent +=
                            '<tr>' +
                                '<td style="padding-right: 10px;">' + article['distributorPartNumber'] + '</td>' +
                                '<td style="padding-right: 10px;">' + article['quantity'] + ' Stück</td>' +
                                '<td style="padding-right: 10px;">' + article['description'] + '</td>' +
                                '<td style="padding-right: 10px;">' + article['project'] + '</td>' +
                            '</tr>';
                    }
                });
                
                // Tabelle abschliessen.
                mailContent += '</table>';
                
                // E-Mail versenden. Das geschieht, indem Empfänger (als User-ID), Betreff und Inhalt in die
                // Firebase Sammlung 'emails' hinzugefügt werden. Die Cloud-Funktion sendMail versendet anschliessend
                // eine E-Mail mit diesen Parametern.
                firebase.firestore().collection('emails').add({
                    userID: usr,
                    subject: mailSubject,
                    message: mailContent
                });
            });
        });
        
        this.setState({orderItems: null});
    };

    render() {
        let copyPasteText = null;
        let uniqueParts = [];
        let nonUniqueParts = [];
        let nonUniquePartsText = '';
		
		
        if (Boolean(this.state.orderItems)) {
            for (let itemKey in this.state.orderItems) {
                const item = this.state.orderItems[itemKey];

                if (!uniqueParts.includes(item.distributorPartNumber)) {
                    uniqueParts.push(item.distributorPartNumber);
                } else {

                    if (!nonUniqueParts.includes(item.distributorPartNumber)) {
                        if(!nonUniquePartsText.includes(item.distributorPartNumber)){
                            nonUniquePartsText += item.distributorPartNumber + ', ';
                        }
                    }
                }
            }
            if (nonUniquePartsText !== '') {
                nonUniquePartsText = nonUniquePartsText.substring(0, nonUniquePartsText.length - 2);
                nonUniquePartsText = 'Darunter ' + uniqueParts.length + ' einmalige Bestellnummern. Folgende ' +
                    'Bestellnummern sind nicht einmalig: ' + nonUniquePartsText;
            }

            copyPasteText = '';
            if (this.props.distributors[this.state.distributor].hasOwnProperty('copyPaste')) {

                for (let itemKey in this.state.orderItems) {
                    const item = this.state.orderItems[itemKey];

                    for (let i = 0; i < this.props.distributors[this.state.distributor].copyPaste.keys.length; i++) {
                        let key = this.props.distributors[this.state.distributor].copyPaste.keys[i];
                        copyPasteText += item[key] + this.props.distributors[this.state.distributor].copyPaste.separator;
                    }

                    copyPasteText = copyPasteText.slice(0, -1);
                    copyPasteText += '\n';
                }
            } else {
                for (let itemKey in this.state.orderItems) {
                    const item = this.state.orderItems[itemKey];
                    copyPasteText += item.distributorPartNumber + '|' + item.quantity + '\n';
                }
            }
        }


        return (
            <React.Fragment>
                {Boolean(this.state.orderItems) ?
                    <Dialog open={Boolean(this.state.orderItems)}
                            onClose={() => this.setState({orderItems: null})}>
                        <DialogTitle>
                            Bestellung bei {this.state.distributor}
                        </DialogTitle>
                        <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                            <DialogContentText>
                                Die Bestellung
                                hat {Object.keys(this.state.orderItems).length} Positionen. {nonUniquePartsText}

                            </DialogContentText>

                            {Boolean(copyPasteText) ?
                                <React.Fragment>
                                    <TextField
                                        multiline
                                        rowsMax={10}
                                        value={copyPasteText}
                                        variant='outlined'
                                        label='Copy Paste'
                                        InputProps={{readOnly: true}}
                                        style={{margin: '20px 0 20px'}}
                                    />
                                    <Button
                                        onClick={() => navigator.clipboard.writeText(copyPasteText)}>Kopieren</Button>
                                </React.Fragment>
                                : null
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={() => this.setState({orderItems: null})}>
                                Abbrechen
                            </Button>
                            <Button color="primary" onClick={this.orderedSelected}>
                                Auswahl bestellt
                            </Button>
                        </DialogActions>
                    </Dialog> : null
                }

                <FormControl variant="outlined" style={{margin: 10, textAlign: 'left'}}>
                    <InputLabel>Lieferant</InputLabel>
                    <Select defaultValue="" label="Lieferant"
                            onChange={(event) => this.fetchOrderItems(event.target.value)}
                    >
                        <MenuItem value=""/>
                        {Object.keys(this.props.distributors).map(key => (
                            <MenuItem key={key} value={key}>{key}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <MyTable
                    rows={this.state.rows}
                    columns={this.columns}
                    allowSelection
                    selectionChanged={this.selectionChanged}
                />
                <div>
                    <Button
                        variant="contained"
                        size='large'
                        color='secondary'
                        startIcon={<BlockIcon/>}
                        style={{margin: '20px 20px 20px', width: 'auto', alignSelf: 'center'}}
                        disabled={Object.keys(this.state.selectedItems).length < 1}
                        onClick={() => this.cancelOrderItems(Object.keys(this.state.selectedItems))}
                    >
                        Auswahl Stoppen
                    </Button>
                    <Button
                        variant="contained"
                        size='large'
                        color='primary'
                        style={{margin: '20px 20px 20px', width: 'auto', alignSelf: 'center'}}
                        disabled={Object.keys(this.state.items).length < 1}
                        onClick={() => this.order(Object.keys(this.state.selectedItems).length === 0 ?
                            this.state.items : this.state.selectedItems)}
                    >
                        {Object.keys(this.state.selectedItems).length === 0 ? 'Alle Bestellen' : 'Auswahl Bestellen'}
                    </Button>
                </div>

            </React.Fragment>
        );
    }
}

export default order;