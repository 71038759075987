import React, {Component} from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import EditableTable, {HeadCell} from '../UI/EditableTable/EditableTable';
import FirebaseGermanErrorMessages from '../../util/FirebaseGermanErrorMessages';

import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Typography
} from '@material-ui/core';

import MyDialog from '../UI/MyDialog/MyDialog';


class orderItems extends Component {
    state = {
        orderItems: {},
        loading: true
    };


    headCells = [
        new HeadCell('createdAt', 'Erstellt', '', 'date', [], undefined, value => value.toLocaleDateString(), false, true),
        new HeadCell('project', 'Projekt'),
        new HeadCell('description', 'Bezeichnung'),
        new HeadCell('distributor', 'Lieferant', '', 'select', this.props.distributors),
        new HeadCell('distributorPartNumber', 'Bestellnummer'),
        new HeadCell('quantity', 'Anzahl', 0, 'number', [], (value) => (value > 0 && value % 1 === 0)),
        new HeadCell('pricePerUnit', 'Preis/Stk.', 0, 'number', [], (value) => (value >= 0), value => parseFloat(value).toFixed(2)),
        new HeadCell('delivered', 'Wareneingang', '', 'number', [], value => (value >= 0 && value % 1 === 0)),
		new HeadCell('comment', 'Kommentar', '', 'text', [], (value) => (true) ),
        new HeadCell('state', 'Status', '', 'select',
            {
                Offen: 'Offen',
                Unterschrieben: 'Unterschrieben',
                Bestellt: 'Bestellt',
                Teillieferung: 'Teillieferung',
                Wareneingang: 'Wareneingang',
                Gestoppt: 'Gestoppt'
            }, undefined, undefined, true, true)
    ];

    componentDidMount() {
        this.authStateUnsubscribe = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.fetchItems();
            }
        });
    }

    fetchItems = () => {
        this.orderItemsUnsubscribe = firebase.firestore().collection('orderItems')
            .orderBy('createdAt', 'desc').limit(10000).onSnapshot(querySnapshot => {
                const orderItems = {};
                querySnapshot.forEach(doc => {
                    orderItems[doc.id] = doc.data();
                    orderItems[doc.id].history?.forEach(event => {
                        event.user = this.props.users[event.user]?.first_name + ' ' +
                            this.props.users[event.user]?.last_name;
                    });
                });
                this.setState({orderItems: orderItems, loading: false});
            });
    };

    componentWillUnmount() {
        if (typeof this.orderItemsUnsubscribe !== 'undefined') {
            this?.orderItemsUnsubscribe();
        }
        if (typeof this.authStateUnsubscribe() !== 'undefined') {
            this?.authStateUnsubscribe();
        }
    }

    deleteItem = (key) => {
        firebase.firestore().collection('orderItems').doc(key).delete().catch(error => {
            alert(error.message);
        });
    };

    editItem = (key, newItem) => {
        for (let prop in newItem) {
            if (newItem.hasOwnProperty(prop) && ( this.state.orderItems[key].hasOwnProperty(prop) || prop === "comment" ))
                if (newItem[prop] !== this.state.orderItems[key][prop]) {
                    const updateObject = {updatedBy: this.props.user.uid}
                    updateObject[prop] = newItem[prop];
                    
                    let sendMail = false;
                    let mailSubject;
                    let mailContent;
                    
                    // Wurde der Kommentar bearbeitet, wird eine E-Mail vorbereitet und an den Besteller versendet.
                    if ( prop === "comment" ) {
                        mailSubject = 'Neuer Kommentar zum bestellten Artikel "' + newItem['description'] + '"';
                        mailContent =
                        '<h3>Neuer Kommentar zum bestellten Artikel "' + newItem['description'] + '"</h3>' +
                        '<p>Hallo ' + this.props.users[newItem['user']]?.first_name + '</p>' +
                        '<p>Zu deinem bestellten Artikel "' + newItem['description'] + '" wurde ein neuer Kommentar hinterlassen:</p>' +
                        '<table style="border: none;">' +
                            '<tr>' +
                                '<td>Projekt</td>' +
                                '<td>' + newItem['project'] + '</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td>Bezeichnung</td>' +
                                '<td>' + newItem['description'] + '</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td>Lieferant</td>' +
                                '<td>' + newItem['distributor'] + '</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td>Bestellnummer</td>' +
                                '<td>' + newItem['distributorPartNumber'] + '</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td>Kommentar</td>' +
                                '<td><b>' + newItem['comment'] + '</b></td>' +
                            '</tr>' +
                        '</table>';
                        
                        // Flag setzen, damit eine E-Mail ausgelöst wird.
                        sendMail = true;
                    }
                    
                    // Objekt in der Datenbank aktualisieren.
                    // Nach erfolgreicher Aktualisierung wird eine E-Mail versendet, sofern das entsprechende Flag gesetzt ist.
                    firebase.firestore().collection('orderItems').doc(key).update(updateObject)
                    .then(() => {
                        if (sendMail) {
                            firebase.firestore().collection('emails').add({
                                userID: newItem['user'],
                                subject: mailSubject,
                                message: mailContent
                            });
                        }
                    })
                    .catch(error => {
                        alert(FirebaseGermanErrorMessages(error));
                    });
                }
        }
    };


    render() {
        for (let key in this.state.orderItems) {
            const orderItem = this.state.orderItems[key];
            orderItem.extendedContent = (
                <React.Fragment>
                    <Typography variant="h6">Geschichte</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Zeitpunkt</TableCell>
                                <TableCell>Bearbeiter</TableCell>
                                <TableCell>Ereignis</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderItem.history?.slice(0).reverse().map((event, index2) => (
                                <TableRow key={index2}>
                                    <TableCell>{
                                        event.timestamp.toDate().toLocaleDateString() + ' ' +
                                        event.timestamp.toDate().toLocaleTimeString()}
                                    </TableCell>
                                    <TableCell>{event.user}</TableCell>
                                    <TableCell>{event.event}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </React.Fragment>
            );
        }


        return (
            <React.Fragment>
                {Object.keys(this.state.orderItems).length > 0 ?
                    <MyDialog
                        data={this.state.orderItems[Object.keys(this.state.orderItems)[0]]}
                        fields={this.headCells}
                    /> : null
                }

                <EditableTable
                    headCells={this.headCells}
                    rows={this.state.orderItems}
                    deleteRow={this.deleteItem}
                    editRow={this.editItem}
                    loading={this.state.loading}
                />
            </React.Fragment>
        );
    }
}

export default orderItems;