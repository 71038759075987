import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - 240px)`,
            marginLeft: '240px'
        }
    }
});

class MyToolbar extends Component {

    render() {

        return (
            <React.Fragment>
                <AppBar position="fixed" className={this.props.classes.appBar}>
                    <Toolbar>
                        <Hidden smUp>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={this.props.drawerOpen}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Hidden>

                        <Typography variant="h6" noWrap>
                            {this.props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </React.Fragment>

        );
    }
}

export default withStyles(styles, {withTheme: true})(MyToolbar);