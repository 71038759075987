import React, {Component} from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';

import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';

import MyTable from '../UI/MyTable/MyTable';
import MyDialog from '../UI/MyDialog/MyDialog';
import OrderItemOverview from '../OrderItemOverview/OrderItemOverview';

class myOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {orders: {}, rows: [], editItem: null, editItemKey: null};
        this.columns = [
            {
                id: 'createdAt',
                label: 'Erstellt',
                renderContent: (row, column) => row[column.id].toDate().toLocaleDateString()
            },
            {id: 'user', label: 'Besteller'},
            {id: 'description', label: 'Beschreibung'},
            {
                id: 'private',
                label: 'Geschäftlich / Privat',
                renderContent: row => row.private ? 'Privat' : 'Geschäftlich'
            },
            {id: 'state', label: 'Status'}
        ];

        this.extendedColumns = [
            {id: 'project', label: 'Projekt', editable: true, validate: value => value !== ''},
            {id: 'description', label: 'Bezeichnung', editable: true, validate: value => value !== ''},
            {
                id: 'distributor',
                label: 'Lieferant',
                editable: true,
                type: 'select',
                options: Object.keys(this.props.distributors),
                validate: value => Object.keys(this.props.distributors).includes(value)
            },
            {id: 'distributorPartNumber', label: 'Bestellnummer', editable: true, validate: value => value !== ''},
            {id: 'quantity', label: 'Anzahl', editable: true, type: 'number', validate: value => (value > 0 && value % 1 === 0)},
            {id: 'pricePerUnit', label: 'Preis/Stk.', editable: true, type: 'number', validate: value => (value >= 0),},
            {id: 'state', label: 'Status'},
            {
                id: 'action', label: 'Aktion', renderContent: (row, column) => {
                    if (row.id === 'total') {
                        return null;
                    }
                    const editItem = {
                        project: row.project,
                        description: row.description,
                        distributor: row.distributor,
                        distributorPartNumber: row.distributorPartNumber,
                        quantity: row.quantity,
                        pricePerUnit: row.pricePerUnit
                    }
                    return (
                        <Fab
                            color='secondary'
                            disabled={row.state !== 'Offen' || Boolean(this.state.editItem)}
                            onClick={() => this.setState({editItem: editItem, editItemKey: row.id})}
                        >
                            <EditIcon/>
                        </Fab>
                    );

                }
            }
        ];
    }

    componentDidMount() {
        this.fetchOrders();
    }

    fetchOrders = () => {
        this.ordersUnsubscribe = firebase.firestore().collection('orders')
            .where('user', '==', this.props.user.uid).orderBy('createdAt', 'desc')
            .onSnapshot(querySnapshot => {
                const orders = {};
                const promises = [];
                querySnapshot.forEach(doc => {
                    orders[doc.id] = doc.data();
                    orders[doc.id].user = this.props.users[doc.data().user]?.first_name + ' ' +
                        this.props.users[doc.data().user]?.last_name;
                    orders[doc.id].items = {};
                    orders[doc.id].orderItems.forEach(orderItemRef => {
                        const promise = orderItemRef.get().then(orderItemDoc => {
                            orders[doc.id].items[orderItemDoc.id] = orderItemDoc.data();
                        });
                        promises.push(promise)
                    });
                });
                Promise.all(promises).then(() => {
                    const rows = Object.keys(orders).map(key => {
                        const row = {};
                        this.columns.forEach(column => {
                            row[column.id] = orders[key][column.id];
                        });
                        row.id = key;
                        row.extendedContent = () => {
                            let total = 0;
                            const extendedRows = Object.keys(orders[key].items).map(extendedKey => {
                                const extendedRow = {};
                                this.extendedColumns.forEach(extendedColumn => {
                                    extendedRow[extendedColumn.id] = orders[key].items[extendedKey][extendedColumn.id];
                                });
                                extendedRow.id = extendedKey;

                                orders[key].items[extendedKey].history?.forEach(event => {
                                    if(this.props.users.hasOwnProperty(event.user)){
                                        event.user = this.props.users[event.user]?.first_name + ' ' +
                                            this.props.users[event.user]?.last_name;
                                    }
                                });
                                extendedRow.extendedContent = () => (
                                    <OrderItemOverview orderItem={orders[key].items[extendedKey]} showData={false}/>
                                );

                                total += extendedRow.pricePerUnit * extendedRow.quantity;
                                return extendedRow;
                            });
                            extendedRows.push({id: 'total', quantity: 'Total', pricePerUnit: total});
                            return (
                                <MyTable
                                    rows={extendedRows}
                                    columns={this.extendedColumns}
                                />
                            );
                        };
                        return row;
                    });
                    this.setState({orders: orders, rows: rows});
                })
            });
    };


    componentWillUnmount() {
        if (typeof this.ordersUnsubscribe === 'function') {
            this?.ordersUnsubscribe();
        }
    }

    saveItemChanges = newData => {
        newData.quantity = parseInt(newData.quantity);
        newData.pricePerUnit = parseFloat(newData.pricePerUnit)
        firebase.firestore().collection('orderItems').doc(this.state.editItemKey)
            .update({...newData, updatedBy: this.props.user.uid}).then(() => this.fetchOrders());
        this.setState({editItem: null, editItemKey: null});
    }

    render() {

        return (
            <React.Fragment>
                {Boolean(this.state.editItem) ?
                    <MyDialog
                        title='Position hinzufügen'
                        open={Boolean(this.state.editItem)}
                        cancelHandler={() => this.setState({editItem: null, editItemKey: null})}
                        saveHandler={this.saveItemChanges}
                        fields={this.extendedColumns.slice(0, -2)}
                        data={this.state.editItem}
                    /> : null}
                <MyTable
                    rows={this.state.rows}
                    columns={this.columns}
                />
            </React.Fragment>
        );
    }
}

export default myOrders;