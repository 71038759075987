import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import {
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@material-ui/core';
import {Done as DoneIcon} from '@material-ui/icons';

import MyTable from '../UI/MyTable/MyTable';
import OrderItemOverview from '../OrderItemOverview/OrderItemOverview';


class deliveries extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orderItems: {},
            rows: [],
            filteredRows: [],
            partFilter: '',
            partialDeliveryKey: null,
            partialDeliveryValue: null
        };
        this.columns = [
            {id: 'user', label: 'Besteller'},
            {id: 'project', label: 'Projekt'},
            {id: 'distributorPartNumber', label: 'Bestellnummer'},
            {id: 'quantity', label: 'Anzahl'},
            {id: 'partialDelivery', label: 'Wareneingang'},
            {
                id: 'action', label: 'Aktion', renderContent: row => (
                    <React.Fragment>
                        <Button
                            variant="contained"
                            style={{marginRight: '20px'}}
                            disabled={this.state.partialDeliveryKey === row.id ||
                            this.state.orderItems[row.id]?.delivered + 1 === this.state.orderItems[row.id]?.quantity}
                            onClick={() => this.setState({
                                partialDeliveryKey: row.id,
                                partialDeliveryValue: this.state.orderItems[row.id]?.delivered
                            })}
                        >
                            Teillieferung
                        </Button>
                        <Button
                            variant="contained"
                            color='primary'
                            startIcon={<DoneIcon/>}
                            onClick={() => this.orderItemDelivered(row.id)}
                        >
                            Geliefert
                        </Button>
                    </React.Fragment>
                )
            }
        ];
    }

    fetchOrderItems = distributor => {
        this.orderItemsUnsubscribe = firebase.firestore().collection('orderItems')
            .where('state', 'in', ['Bestellt', 'Teillieferung'])
            .where('distributor', '==', distributor)
            .onSnapshot(querySnapshot => {
                const items = {};
                querySnapshot.forEach(doc => {
                    items[doc.id] = doc.data();
                    items[doc.id].user = this.props.users[doc.data().user]?.first_name + ' ' +
                        this.props.users[doc.data().user]?.last_name;
                    items[doc.id].partialDelivery = items[doc.id].delivered;
                });
                const rows = Object.keys(items).map(key => {
                    const row = {};
                    this.columns.forEach(column => {
                        row[column.id] = items[key][column.id];
                    });
                    row.id = key;

                    items[key].history?.forEach(event => {
                        event.user = this.props.users[event.user]?.first_name + ' ' +
                            this.props.users[event.user]?.last_name;
                    });
                    row.extendedContent = (
                        <OrderItemOverview orderItem={items[key]}/>
                    );
                    return row;
                });
                this.setState({orderItems: items, rows: rows}, () => this.searchChanged(this.state.partFilter));
            });
    };

    componentWillUnmount() {
        if (typeof this.orderItemsUnsubscribe !== 'undefined') {
            this?.orderItemsUnsubscribe();
        }
    }


    orderItemDelivered = (id) => {
        firebase.firestore().collection('orderItems').doc(id).update({
            delivered: this.state.orderItems[id].quantity,
            state: 'Wareneingang',
            updatedBy: this.props.user.uid
        });
        document.getElementById('bestellnummerTextField').focus();
    };

    partialDeliveryValueChanged = value => {
        this.setState({partialDeliveryValue: value});
    };

    partialDelivery = () => {
        let partialDelivery = parseFloat(this.state.partialDeliveryValue);
        if (partialDelivery < this.state.orderItems[this.state.partialDeliveryKey].quantity &&
            partialDelivery > this.state.orderItems[this.state.partialDeliveryKey].delivered && partialDelivery % 1 === 0) {
            firebase.firestore().collection('orderItems').doc(this.state.partialDeliveryKey).update({
                delivered: partialDelivery,
                state: 'Teillieferung',
                updatedBy: this.props.user.uid
            });
            this.setState({partialDeliveryKey: null}, () => {
                document.getElementById('bestellnummerTextField').focus();
            });
        } else {
            alert('Eingegebene Teillieferung ungültig.');
            this.setState({partialDeliveryValue: this.state.orderItems[this.state.partialDeliveryKey].delivered});
        }
    };

    searchChanged = (value) => {
        this.setState(prevState => {
            prevState.filteredRows = (prevState.rows.filter(row =>
                row.distributorPartNumber.toUpperCase().includes(value.toUpperCase())));
            return {partFilter: value, filteredRows: prevState.filteredRows};
        });
    };


    render() {
        const rows = this.state.filteredRows;
        return (
            <React.Fragment>
                {Boolean(this.state.partialDeliveryKey) ?
                    <Dialog open={Boolean(this.state.partialDeliveryKey)}
                            onClose={() => this.setState({partialDeliveryKey: null})}>
                        <DialogTitle>Teillieferung erfassen</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Bitte erfasse die Anzahl an gelieferten Einheiten.</DialogContentText>
                            <TextField
                                onChange={(event) => {
                                    this.partialDeliveryValueChanged(event.target.value);
                                }}
                                value={this.state.partialDeliveryValue}
                                fullWidth
                                type='number'
                                autoFocus
                                onFocus={event => event.target.select()}
                                InputProps={{
                                    inputProps: {
                                        min: this.state.orderItems[this.state.partialDeliveryKey]?.delivered,
                                        max: this.state.orderItems[this.state.partialDeliveryKey]?.quantity - 1
                                    }
                                }}
                                onKeyDown={(event) => {
                                    event.stopPropagation();
                                    if (event.key === 'Enter') {
                                        this.partialDelivery();
                                    }
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <DialogActions>
                                <Button onClick={() => this.setState({partialDeliveryKey: null})} color="primary">
                                    Abbrechen
                                </Button>
                                <Button onClick={() => {
                                    this.partialDelivery(this.state.partialDelivery);
                                }}
                                        color="primary">
                                    Speichern
                                </Button>
                            </DialogActions>
                        </DialogActions>
                    </Dialog> : null}
                <FormControl variant="outlined" style={{margin: 10, textAlign: 'left'}}>
                    <InputLabel>Lieferant</InputLabel>
                    <Select defaultValue="" label="Lieferant"
                            onChange={(event) => this.fetchOrderItems(event.target.value)}
                    >
                        <MenuItem value=""/>
                        {Object.keys(this.props.distributors).map(key => (
                            <MenuItem key={key} value={key}>{key}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label='Bestellnummer'
                    variant='outlined'
                    style={{margin: 10}}
                    value={this.state.partFilter}
                    onChange={event => this.searchChanged(event.target.value)}
                    onFocus={event => event.target.select()}
                    id='bestellnummerTextField'
                />
                <MyTable
                    rows={rows}
                    columns={this.columns}
                />
            </React.Fragment>
        );
    }
}

export default deliveries;