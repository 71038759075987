import React, {Component} from 'react';
import {Route, Switch, Redirect, withRouter} from 'react-router-dom';

import firebase from 'firebase/app';
import 'firebase/auth';

import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CreateIcon from '@material-ui/icons/Create';
import StoreIcon from '@material-ui/icons/Store';
import { LicenseInfo } from '@material-ui/x-grid';

import './App.css';
import Layout from './components/Layout/Layout';
import OrderPositions from './components/OrderItems/OrderItems';
import UserInfo from './components/Layout/Navigation/Drawer/UserInfo/UserInfo';
import LoginForm from './components/LoginForm/LoginForm';
import NewOrder from './components/NewOrder/NewOrder';
import MyOrders from './components/MyOrders/MyOrders';
import Deliveries from './components/Deliveries/Deliveries';
import SignOrders from './components/SignOrders/SignOrders'
import Order from './components/Order/Order'

if (!firebase.apps.length) {
    if(window.location.hostname === 'bestellwesen.bbcag.ch'){
        firebase.initializeApp({
            apiKey: 'AIzaSyBd9YxNn2fQxaSi99fgI03FbYEkv8p965g',
            authDomain: 'ordering-tool-c498b.firebaseapp.com',
            databaseURL: 'https://ordering-tool-c498b-default-rtdb.europe-west1.firebasedatabase.app',
            projectId: 'ordering-tool-c498b',
            storageBucket: 'ordering-tool-c498b.appspot.com',
            messagingSenderId: '717013435225',
            appId: '1:717013435225:web:813111b9e725deafff0248'
        });
    } else {
        firebase.initializeApp({
            apiKey: "AIzaSyCUcVsjWgb8I26kmqCEkmG10fNNSgdzYac",
            authDomain: "order-tool-dev.firebaseapp.com",
            projectId: "order-tool-dev",
            storageBucket: "order-tool-dev.appspot.com",
            messagingSenderId: "13500895910",
            appId: "1:13500895910:web:931aa40f50f904a50b5a67"
        });
    }
} else {
    firebase.app();
}

LicenseInfo.setLicenseKey(
    '4c72fd51198df51456612b5ad5598d8aT1JERVI6MjE3OTAsRVhQSVJZPTE2NDU4OTAzOTgwMDAsS0VZVkVSU0lPTj0x',
);

class Page {
    constructor(title, link, icon, content, role) {
        this.title = title;
        this.link = link;
        this.icon = icon;
        this.content = content;
        this.role = role;
    }
}


class App extends Component {
    state = {user: null, users: {}, distributors: {}, loading: true};

    componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
            this.setState({user: user});
            if (user && user.emailVerified) {
                if (this.props.location.pathname === '/login') {
                    this.props.history.push('/');
                }
                firebase.firestore().collection('users').onSnapshot(querySnapshot => {
                    const users = {};
                    querySnapshot.forEach(doc => {
                        users[doc.id] = doc.data();
                    });
                    this.setState({users: users});
                });
                firebase.firestore().collection('distributors').onSnapshot(querySnapshot => {
                    const distributors = {};
                    querySnapshot.forEach(doc => {
                        distributors[doc.id] = doc.data();
                    })
                    const sortedKeys = Object.keys(distributors).sort((a,b) => {
                        if((distributors[a].sortPriority < distributors[b].sortPriority) ||
                            distributors[b].sortPriority === undefined){
                            return -1;
                        }
                        if((distributors[b].sortPriority < distributors[a].sortPriority) ||
                            distributors[a].sortPriority === undefined){
                            return 1;
                        }
                        return 0;
                    })
                    const sortedDistributors = {};
                    sortedKeys.forEach(key => {
                        sortedDistributors[key] = distributors[key];
                    })
                    this.setState({distributors: sortedDistributors})
                });
            } else {
                this.props.history.push('/login');
            }
        });
    }

    render() {
        if (this.state.user && this.state.user.emailVerified && Object.keys(this.state.users).length > 0 && Object.keys(this.state.distributors).length > 0) {
            let pages = [
                new Page('Home', '/home', <HomeIcon/>, <Typography>Home</Typography>),
                new Page('Neue Bestellung', '/new-order', <AddCircleIcon/>,
                    <NewOrder
                        user={this.state.user}
                        users={this.state.users}
                        distributors={this.state.distributors}
                    />),
                new Page('Meine Bestellungen', '/my-orders', <ReceiptIcon/>,
                    <MyOrders
                        user={this.state.user}
                        users={this.state.users}
                        distributors={this.state.distributors}
                    />),
                new Page('Bestellungen signieren', '/sign-orders', <CreateIcon/>, <SignOrders
                    user={this.state.user}
                    users={this.state.users}
                    distributors={this.state.distributors}
                />, 'coach'),
                new Page('Bestellung aufgeben', '/order', <StoreIcon/>, <Order
                    user={this.state.user}
                    users={this.state.users}
                    distributors={this.state.distributors}
                />, 'coach'),
                new Page('Wareneingang', '/deliveries', <CallReceivedIcon/>,
                    <Deliveries
                        user={this.state.user}
                        users={this.state.users}
                        distributors={this.state.distributors}
                    />, 'coach'),

                new Page('Bestellpositionen', '/order-positions', <ViewHeadlineIcon/>,
                    <OrderPositions
                        user={this.state.user}
                        users={this.state.users}
                        distributors={this.state.distributors}
                    />, 'coach')
            ];
            pages = pages.filter(page => {
                return typeof page.role === 'undefined' || (page.role === this.state.users[this.state.user?.uid]?.role);
            });

            const pageRoutes = pages.map(page => {
                return (
                    <Route path={page.link} render={() => page.content} key={page.link}/>
                );
            });
            return (

                <div className="App">
                    <Layout
                        pages={pages}
                        userInfo={
                            <UserInfo
                                name={
                                    this.state.users[this.state.user?.uid]?.first_name + ' ' +
                                    this.state.users[this.state.user?.uid]?.last_name
                                }
                                logout={() => {
                                    firebase.auth().signOut();
                                }}
                            />
                        }
                    >
                        <Switch>
                            {pageRoutes}
                            <Redirect from="/" to="/home" exact/>
                            <Route render={() => <Typography variant="h2">Not Found</Typography>}/>
                        </Switch>
                    </Layout>
                </div>
            );
        }else {
            return (
                <div className="App">
                    <Switch>
                        <Route path="/login" render={() => <LoginForm/>}/>
                    </Switch>
                </div>
            );
        }
    }
}

export default withRouter(App);
