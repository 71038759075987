import React, {Component} from 'react';

import {
    IconButton,
    MenuItem,
    Menu,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    Tooltip
} from '@material-ui/core';
import {MoreVert as MoreVertIcon} from '@material-ui/icons';
import {XGrid, GridToolbar} from '@material-ui/x-grid';

import TextField from '@material-ui/core/TextField';

const testEmpty = (value) => {
    return value !== '';
};

export class HeadCell {
    constructor(/*string*/ id, /*string*/ label, initialValue = '',/*string*/ type = 'text',
                /*string[]*/ options = [], /*function*/ valueTest = testEmpty,
                /*function*/ formatValue = (value) => value, /*boolean*/ editable = true,
                /*boolean*/ show = true) {
        this.id = id;
        this.label = label;
        this.initialValue = initialValue;
        this.type = type;
        this.editable = editable;
        this.options = options;
        this.show = show;
        this.valueTest = valueTest;
        this.formatValue = formatValue;
        switch (this.type) {
            case 'text':
            case 'number':
            case 'select':
                this.sort = (a, b) => {
                    a = a.toUpperCase();
                    b = b.toUpperCase();
                    return a > b ? 1 : a < b ? -1 : 0;
                };
                break;
            case 'date':
                this.sort = (a, b) => (a.toDate() - b.toDate());
                break;
            default:
        }
    }
}

class editableTable extends Component {
    state = {
        openRows: [],
        menuAnchorElement: null,
        menuRowKey: null,
        editRowKey: null,
        editRow: null,
        showInfo: null
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !(nextState.editRow !== null && this.state.editRow !== null);
    }

    openMenuHandler = (event, key) => {
        this.setState({menuAnchorElement: event.currentTarget, menuRowKey: key});
    };

    closeMenuHandler = () => {
        this.setState({menuAnchorElement: null, menuRowKey: null});
    };

    editRow = (key) => {
        this.setState({editRowKey: key, editRow: {...this.props.rows[key]}});
    };

    addRow = () => {
        let newRow = {};
        this.props.headCells.forEach(headCell => {
            newRow[headCell.id] = headCell.initialValue;
        });
        this.setState({editRow: newRow});
    };

    editAbort = () => {
        this.setState({editRowKey: null, editRow: null});
    };

    editSave = () => {
        let output = '';
        const editRow = {...this.state.editRow}
        this.props.headCells.forEach(headCell => {
            if (headCell.editable && typeof headCell.valueTest !== 'undefined') {
                if (!headCell.valueTest(editRow[headCell.id])) {
                    output += headCell.label + ' "' + editRow[headCell.id] + '" ist ungültig.\n';
                }
            }
            if(headCell.type === 'number'){
                editRow[headCell.id] = parseFloat(editRow[headCell.id]);
            }
        });
        if (output !== '') {
            alert(output);
        } else {
            if (this.state.editRowKey === null) {
                this.props.addRow(editRow);
            } else {
                this.props.editRow(this.state.editRowKey, editRow);
            }
            this.setState({editRowKey: null, editRow: null});
        }
    };

    valueChanged = (key, value) => {
        this.setState(prevState => {
            prevState.editRow[key] = value;
            return {editRow: prevState.editRow};
        });
    };

    render() {
        let extendedContent = false;
        for (let key in this.props.rows) {
            if (typeof this.props.rows[key].extendedContent !== 'undefined') {
                extendedContent = true;
            }
        }

        const headCells = this.props.headCells.reduce((prev, curr) => {
            if (typeof curr.id === 'undefined' || !curr.show) {
                return prev;
            } else {
                prev.push(curr);
                return prev;
            }
        }, []);

        const rows = Object.keys(this.props.rows).map(key => {
            const row = {};
            headCells.forEach(headCell => {
                if (headCell.type === 'date') {
                    row[headCell.id] = this.props.rows[key][headCell.id].toDate();
                } else {
                    row[headCell.id] = this.props.rows[key][headCell.id];
                }
            });
            row['id'] = key;
            return row;
        });


        const columns = headCells.map((headCell, index) => {
            const maxCommentLength = 14; // Maximale, direkt angezeigte Länge eines Kommentars. Längere Kommentare werden gekürzt und nur als Tooltip vollständig angezeigt.
            const column = {field: headCell.id, headerName: headCell.label, flex: 1};
            if (typeof headCell.formatValue !== 'undefined') {
                column['valueFormatter'] = param => headCell.formatValue(param.value);
            }
            if (headCell.type === 'number' || headCell.type === 'date') {
                column['type'] = headCell.type;
            }
            if (headCell.id === "comment") {
                column['renderCell'] = params => (
                    <Tooltip title={(params.value ? params.value : "")} placement="bottom-end">
                        <div>
                            {params.value?.length > maxCommentLength ? params.value?.slice(0,maxCommentLength) + "..." : params.value}
                        </div>
                    </Tooltip>
                    
                );
            }
            else if (index === headCells.length - 1) {
                column['renderCell'] = params => (
                    <div style={{justifyContent: 'flex-end', display: 'flex', width: '100%'}}>
                        {headCell.formatValue === 'undefined' ? params.value : headCell.formatValue(params.value)}
                        <IconButton
                            onClick={(event) => this.openMenuHandler(event, params.row.id)}
                            disabled={this.state.showInfo !== null || this.state.editRow !== null}
                        >
                            <MoreVertIcon/>
                        </IconButton>
                    </div>
                );
            }
            return column;
        });

        return (
            <React.Fragment>
                {this.state.editRow !== null ?
                    <Dialog
                        open={Boolean(this.state.editRow)}
                        onClose={this.editAbort}
                        title="Bearbeiten"
                    >
                        <DialogTitle>Bearbeiten</DialogTitle>
                        <DialogContent>
                            {this.props.headCells.map((headCell, index) => {
                                if (headCell.editable) {
                                    return (
                                        <TextField
                                            key={headCell.id}
                                            autoFocus={index === 0}
                                            margin='dense'
                                            label={headCell.label}
                                            fullWidth
                                            defaultValue={this.state.editRow[headCell.id]}
                                            onChange={(event) => {
                                                this.valueChanged(headCell.id, event.target.value);
                                            }}
                                            type={headCell.type}
                                            select={headCell.type === 'select'}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter' && headCell.type !== 'select') {
                                                    this.editSave();
                                                }
                                            }}
                                        >
                                            {typeof headCell.options === 'undefined' ? null : Object.keys(headCell.options).map((key) => (
                                                <MenuItem key={key} value={key}>{key}</MenuItem>
                                            ))}
                                        </TextField>
                                    );
                                }
                                return null;
                            })}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.editAbort} color="primary">
                                Abbrechen
                            </Button>
                            <Button onClick={this.editSave} color="primary">
                                Speichern
                            </Button>
                        </DialogActions>
                    </Dialog> : null}

                {extendedContent && this.state.showInfo !== null ?
                    <Dialog open={Boolean(this.state.showInfo)} onClose={() => this.setState({showInfo: null})}>
                        <DialogContent>
                            {this.props.rows[this.state.showInfo].extendedContent}
                        </DialogContent>
                    </Dialog> : null
                }
                {typeof this.props.addRow !== 'undefined' ?
                    <Button
                        variant="contained"
                        size='large'
                        color='primary'
                        style={{margin: '20px 20px 20px', width: 'auto', alignSelf: 'center'}}
                        onClick={this.addRow}
                        disabled={this.state.editRow !== null}
                    >
                        Position hinzufügen
                    </Button> : null}

                <div style={{width: '100%', flexGrow: '1'}}>
                    <XGrid
                        rows={rows}
                        columns={columns}
                        // autoHeight
                        loading={this.props.loading}
                        // hideFooter
                        autoPageSize
                        pagination
                        disableSelectionOnClick
                        showToolbar
                        components={{Toolbar: GridToolbar}}
                        scro
                    />
                </div>
                <Menu anchorEl={this.state.menuAnchorElement} open={Boolean(this.state.menuAnchorElement)}
                      onClose={this.closeMenuHandler}>
                    <MenuItem
                        onClick={() => {
                            this.editRow(this.state.menuRowKey);
                            this.closeMenuHandler();
                        }}>
                        Bearbeiten
                    </MenuItem>
                    {extendedContent ?
                        <MenuItem
                            onClick={() => {
                                this.setState({showInfo: this.state.menuRowKey});
                                this.closeMenuHandler();
                            }}>
                            Mehr Infos
                        </MenuItem> : null
                    }
                    <MenuItem
                        onClick={() => {
                            this.props.deleteRow(this.state.menuRowKey);
                            this.closeMenuHandler();
                        }}>
                        Löschen
                    </MenuItem>
                </Menu>
            </React.Fragment>
        );
    }
}

export default editableTable;