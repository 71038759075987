import React, {Component} from 'react';

import {Typography} from '@material-ui/core';

import MyTable from '../UI/MyTable/MyTable';

const dataColumns = [
    {
        id: 'createdAt',
        label: 'Erstellt',
        renderContent: (row, column) => (row[column.id].toDate().toLocaleDateString())
    },
    {id: 'user', label: 'Besteller'},
    {id: 'project', label: 'Projekt'},
    {id: 'description', label: 'Bezeichnung'},
    {id: 'distributor', label: 'Lieferant'},
    {id: 'distributorPartNumber', label: 'Bestellnummer'},
    {id: 'quantity', label: 'Anzahl'},
    {id: 'pricePerUnit', label: 'Preis/Stk.'},
    {id: 'delivered', label: 'Wareneingang'},
    {id: 'state', label: 'Status'}
];

const historyColumns = [
    {id: 'timestamp', label: 'Zeitpunkt', renderContent: (row, column) => (row[column.id].toDate().toLocaleString())},
    {id: 'user', label: 'Bearbeiter'},
    {id: 'event', label: 'Ereignis'}
];

class orderItemOverview extends Component {

    render() {
        const historyRows = this.props.orderItem?.history.reverse().map((event, index) => {
            return {...event, id: index};
        });

        return (
            <React.Fragment>
                <div />
                {typeof this.props.showData === 'undefined' || this.props.showData ?
                    <React.Fragment>
                        <Typography variant='h6'>Daten</Typography>
                        <MyTable
                            columns={dataColumns}
                            rows={[{...this.props.orderItem, id: 'values'}]}
                        />
                    </React.Fragment> : null}
                {typeof this.props.showHistory === 'undefined' || this.props.showHistory ?
                    <React.Fragment>
                        <Typography variant='h6'>Geschichte</Typography>
                        <MyTable
                            columns={historyColumns}
                            rows={historyRows}
                        />
                    </React.Fragment> : null}
            </React.Fragment>


        );
    }

}

export default orderItemOverview;