import React, {Component} from 'react';

import {TableRow, TableCell, IconButton, Checkbox} from '@material-ui/core';
import {KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowDown as KeyboardArrowDownIcon} from '@material-ui/icons';

import MyCell from '../MyCell/MyCell';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

class myRow extends Component {
    state = {open: false, selected: false};

    toggleExtendedContent = (event) => {
        if(typeof this.props.row.extendedContent !== 'undefined'){
            this.setState(prevState => ({open: !prevState.open}));
        }
        event.stopPropagation();
    };

    toggleSelected = () => {
        if(this.props.allowSelection){
            this.setState(prevState => ({selected: !prevState.selected}), () => {
                this.props.selectionChanged(this.props.row.id, this.state.selected)
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <TableRow hover={this.props.allowSelection} onClick={this.toggleSelected} selected={this.state.selected}>
                    {this.props.extendedContent || this.props.allowSelection ?
                        <TableCell>
                            {typeof this.props.row.extendedContent !== 'undefined' ?
                                <IconButton size="small" onClick={event => this.toggleExtendedContent(event)}>
                                    {this.state.open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                                </IconButton> : null
                            }
                            {this.props.allowSelection ? <Checkbox checked={this.state.selected}/> : null}
                        </TableCell> : null
                    }
                    {this.props.columns.map(column => (
                        <MyCell
                            key={column.id}
                            row={this.props.row}
                            column={column}
                        />
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0, border: 'none'}} colSpan={this.props.columns.length + 1}>
                        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                {typeof this.props.row.extendedContent === 'function' ?
                                    this.props.row.extendedContent(this.props.row) : this.props.row.extendedContent}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>


        );
    }
}

export default myRow;