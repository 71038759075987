import React, {Component} from 'react';

import {Button, TextField} from '@material-ui/core';
import {Done as DoneIcon, Block as BlockIcon} from '@material-ui/icons';

import firebase from 'firebase/app';
import 'firebase/firestore';

import MyTable from '../UI/MyTable/MyTable';

class signOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {orders: {}, rows: [], filteredRows: [], userFilter: '', projectFilter: ''};
        this.columns = [
            {id: 'user', label: 'Besteller'},
            {id: 'description', label: 'Beschreibung'},
            {
                id: 'private',
                label: 'Geschäftlich / Privat',
                renderContent: row => row.private ? 'Privat' : 'Geschäftlich'
            },
            {
                id: 'action', label: 'Aktion', renderContent: row => (
                    <React.Fragment>
                        <Button variant="contained" color='secondary' style={{marginRight: '20px'}}
                                startIcon={<BlockIcon/>}
                                onClick={() => this.orderRejected(row.id)}>
                            Ablehnen
                        </Button>
                        <Button variant="contained" color='primary' startIcon={<DoneIcon/>}
                                onClick={() => this.orderSigned(row.id)}>
                            Bestätigen
                        </Button>
                    </React.Fragment>
                )
            }
        ];
        this.extendedColumns = [
            {id: 'project', label: 'Projekt'},
            {id: 'description', label: 'Bezeichnung'},
            {id: 'distributor', label: 'Lieferant'},
            {id: 'distributorPartNumber', label: 'Bestellnummer'},
            {id: 'quantity', label: 'Anzahl'},
            {
                id: 'pricePerUnit', label: 'Preis/Stk.', renderContent: (row, column) =>
                    parseFloat(row[column.id]).toFixed(2)
            }
        ];
    }

    componentDidMount() {
        this.fetchOrders();
    }

    fetchOrders = () => {
        this.ordersUnsubscribe = firebase.firestore().collection('orders')
            .where('state', '==', 'Erstellt')
            .onSnapshot(querySnapshot => {
                const orders = {};
                querySnapshot.forEach(doc => {
                    orders[doc.id] = doc.data();
                    orders[doc.id].user = this.props.users[doc.data().user]?.first_name + ' ' +
                        this.props.users[doc.data().user]?.last_name;
                    orders[doc.id].items = {};
                    orders[doc.id].orderItems.forEach(orderItemRef => {
                        orderItemRef.get().then(orderItemDoc => {
                            orders[doc.id].items[orderItemDoc.id] = orderItemDoc.data();
                        });
                    });
                });
                const rows = Object.keys(orders).map(key => {
                    const row = {};
                    this.columns.forEach(column => {
                        row[column.id] = orders[key][column.id];
                    });
                    row.id = key;
                    row.extendedContent = () => {
                        let total = 0;
                        const extendedRows = Object.keys(orders[key].items).map(extendedKey => {
                            const extendedRow = {};
                            this.extendedColumns.forEach(extendedColumn => {
                                extendedRow[extendedColumn.id] = orders[key].items[extendedKey][extendedColumn.id];
                            });
                            extendedRow.id = extendedKey;
                            total += extendedRow.pricePerUnit * extendedRow.quantity;
                            return extendedRow;
                        });
                        extendedRows.push({id: 'total', quantity: 'Total', pricePerUnit: total.toFixed(2)});
                        return (
                            <MyTable
                                rows={extendedRows}
                                columns={this.extendedColumns}
                            />
                        );
                    };
                    return row;
                });
                this.setState({orders: orders, rows: rows}, () => this.filter());
            });
    };


    componentWillUnmount() {
        if (typeof this.ordersUnsubscribe === 'function') {
            this?.ordersUnsubscribe();
        }
    }

    orderSigned = id => {
        firebase.firestore().collection('orders').doc(id).update({
            state: 'Unterschrieben',
            updatedBy: this.props.user.uid
        });
    };

    orderRejected = id => {
        firebase.firestore().collection('orders').doc(id).update({
            state: 'Abgelehnt',
            updatedBy: this.props.user.uid
        });
    };


    filter = () => {
        const filteredRows = (this.state.rows.filter(row =>
            row.user.toUpperCase().includes(this.state.userFilter.toUpperCase()) &&
            row.description.toUpperCase().includes(this.state.projectFilter.toUpperCase())));
        this.setState({filteredRows: filteredRows});
    };


    render() {
        const rows = this.state.filteredRows;


        return (
            <React.Fragment>
                <TextField
                    label='Besteller'
                    variant='outlined'
                    style={{margin: 10}}
                    value={this.state.userFilter}
                    onChange={event => {
                        this.setState({userFilter: event.target.value}, this.filter);
                    }}
                />
                <TextField
                    label='Projekt'
                    variant='outlined'
                    style={{margin: 10}}
                    value={this.state.projectFilter}
                    onChange={event => {
                        this.setState({projectFilter: event.target.value}, this.filter);
                    }}
                />
                <MyTable
                    rows={rows}
                    columns={this.columns}
                />
            </React.Fragment>
        );
    }
}

export default signOrders;