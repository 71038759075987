import React, {Component} from 'react';

import 'firebase/firestore';

import {Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem} from '@material-ui/core';

class myDialog extends Component {
    state = {data: {}};

    componentDidMount() {
        this.setState({data: {...this.props.data}});
    }

    updateData = (field, value) => {
        this.setState(prevState => {
            prevState.data[field.id] = value;
            return {data: prevState.data};
        });
    };

    save = () => {
        let error = '';
        this.props.fields.forEach(field => {
            if (typeof field.validate === 'function' && this.state.data.hasOwnProperty(field.id)) {
                if (!field.validate(this.state.data[field.id])) {
                    error += field.label + ' "' + this.state.data[field.id] + '" ist ungültig.\n';
                }
            }
        });
        if (error !== '') {
            alert(error);
            return;
        }
        this.props.saveHandler(this.state.data);
    };

    render() {

        if (this.props.open && Object.keys(this.state.data).length > 0) {
            return (
                <Dialog onClose={this.props.cancelHandler} open={true}>
                    <DialogTitle>{this.props.title}</DialogTitle>
                    <DialogContent>
                        {this.props.fields.map((field, index) => {
                            if (this.state.data.hasOwnProperty(field.id)) {
                                return (
                                    <TextField
                                        key={field.id}
                                        label={field.label}
                                        autoFocus={index === 0}
                                        value={this.state.data[field.id]}
                                        disabled={!field.editable}
                                        InputProps={{readOnly: !field.editable}}
                                        select={field.type === 'select'}
                                        type={field.type}
                                        fullWidth
                                        margin='normal'
                                        onChange={(event) => this.updateData(field, event.target.value)}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter' && field.type !== 'select') {
                                                this.save();
                                            }
                                        }}
                                    >
                                        {field.type === 'select' ? field.options.map(option => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        )) : null}
                                    </TextField>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </DialogContent>
                    <DialogActions>
                        <DialogActions>
                            <Button onClick={this.props.cancelHandler} color="primary">
                                Abbrechen
                            </Button>
                            <Button onClick={this.save} color="primary">
                                Speichern
                            </Button>
                        </DialogActions>
                    </DialogActions>
                </Dialog>
            );
        } else {
            return null;
        }
    }
}

export default myDialog;