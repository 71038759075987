import React, {Component} from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';

import {
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    MenuItem,
    Menu,
    Dialog,
    Chip,
    Tooltip
} from '@material-ui/core';
import {
    MoreVert as MoreVertIcon,
    Delete as DeleteIcon,
    CloudUpload as CloudUploadIcon,
    Add as AddIcon,
    Send as SendIcon
} from '@material-ui/icons';

import {Importer, ImporterField} from 'react-csv-importer';
import './ImporterStyles.css';

import OrderSummary from './OrderSummary/OrderSummary';
import MyTable from '../UI/MyTable/MyTable';
import MyDialog from '../UI/MyDialog/MyDialog';


class newOrder extends Component {
    state = {
        orderItems: {},
        rows: [],
        loading: true,
        orderSummary: false,
        orderDescription: '',
        privateOrder: false,
        menuAnchorElement: null,
        menuRowKey: null,
        editItemKey: null,
        editItem: null,
        selectedItems: [],
        import: false
    };

    columns = [
        {id: 'project', label: 'Projekt', editable: true, validate: value => value !== ''},
        {id: 'description', label: 'Bezeichnung', editable: true, validate: value => value !== ''},
        {
            id: 'distributor',
            label: 'Lieferant',
            editable: true,
            type: 'select',
            options: Object.keys(this.props.distributors),
            validate: value => Object.keys(this.props.distributors).includes(value)
        },
        {
            id: 'distributorPartNumber', label: 'Bestellnummer', editable: true, validate: value => value !== '',
            renderContent: (row, column) => {
                if (this.state.orderItems[row.id].distributorPartNotFound) {
                    return (
                        <Tooltip title='Bestellnummer nicht gefunden'>
                            <Chip label={row[column.id]} color='secondary'/>
                        </Tooltip>
                    );
                } else {
                    return row[column.id];
                }
            }
        },
        {
            id: 'quantity',
            label: 'Anzahl',
            editable: true,
            type: 'number',
            validate: value => (value > 0 && value % 1 === 0),
            renderContent: (row, column) => {

                return (
                    <Tooltip title={'Lager: ' + this.state.orderItems[row.id].distributorAvailability +
                    '\nMindestbestellmenge: ' + this.state.orderItems[row.id].distributorMinOrderQuantity}>
                        {(this.state.orderItems[row.id].distributorAvailability < parseFloat(this.state.orderItems[row.id].quantity)) ||
                        (this.state.orderItems[row.id].distributorMinOrderQuantity > parseFloat(this.state.orderItems[row.id].quantity)) ?
                            <Chip label={row[column.id]} color='secondary'/> : <div>{row[column.id]}</div>
                        }

                    </Tooltip>
                );
            }
        },
        {
            id: 'pricePerUnit',
            label: 'Preis/Stk.',
            editable: true,
            type: 'number',
            validate: value => (value >= 0),
            renderContent: (row, column) => (
                <div style={{justifyContent: 'space-between', display: 'flex', alignItems: 'center'}}>
                    {parseFloat(row[column.id]).toFixed(2)}
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            this.openMenuHandler(event, row.id);
                        }}
                        disabled={Boolean(this.state.editItem)}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                </div>
            )
        }
    ];

    componentDidMount = () => {
        this.cartUnsubscribe = firebase.firestore().collection('users/' + this.props.user.uid + '/cart')
            .orderBy('createdAt', 'desc').onSnapshot(querySnapshot => {
                const orderItems = {};
                querySnapshot.forEach(doc => {
                    orderItems[doc.id] = doc.data();
                });

                const rows = Object.keys(orderItems).map(key => {
                    const row = {};
                    this.columns.forEach(column => {
                        row[column.id] = orderItems[key][column.id];
                    });
                    row.id = key;
                    return row;
                });

                this.setState({orderItems: orderItems, rows: rows, loading: false});
            });
    }
    ;

    componentWillUnmount = () => {
        if (typeof this.cartUnsubscribe === 'function') {
            this.cartUnsubscribe();
        }
    }
    ;

    openMenuHandler = (event, key) => {
        this.setState({menuAnchorElement: event.currentTarget, menuRowKey: key});
    }
    ;

    closeMenuHandler = () => {
        this.setState({menuAnchorElement: null, menuRowKey: null});
    }
    ;

    deleteItems = (keys) => {
        keys.forEach(key => {
            firebase.firestore().collection('users/' + this.props.user.uid + '/cart')
                .doc(key).delete();
        });
    }
    ;

    editItem = key => {
        this.setState({editItemKey: key, editItem: {...this.state.orderItems[key]}});
    }
    ;

    addItem = () => {
        this.setState({
            editItem: {
                description: '',
                distributor: '',
                distributorPartNumber: '',
                pricePerUnit: 0,
                project: '',
                quantity: 0
            }
        });
    }
    ;

    saveItemChanges = newData => {
        if (Boolean(this.state.editItemKey)) {
            firebase.firestore().collection('users/' + this.props.user.uid + '/cart')
                .doc(this.state.editItemKey).update(newData);
        } else {
            firebase.firestore().collection('users/' + this.props.user.uid + '/cart').add({
                ...newData,
                createdAt: firebase.firestore.Timestamp.now()
            });
        }
        this.setState({editItem: null, editItemKey: null});
    }
    ;

    order = () => {
        this.setState({orderSummary: true});
    }
    ;

    closeOrderSummary = (result) => {
        if (result === 'ordered') {
            const itemRefs = [];
            const batch = firebase.firestore().batch();
            for (let itemId in this.state.orderItems) {
                const item = {...this.state.orderItems[itemId]};
                delete item.distributorPartNotFound;
                delete item.distributorAvailability;
                delete item.distributorMinOrderQuantity;
                item.quantity = parseFloat(item.quantity);
                item.pricePerUnit = parseFloat(item.pricePerUnit);
                const ref = firebase.firestore().collection('orderItems').doc();
                itemRefs.push(ref);
                batch.set(ref, {
                    ...item,
                    user: firebase.auth().currentUser.uid,
                    state: 'Offen',
                    delivered: 0
                })
            }
            for(let itemId in this.state.orderItems){
                const ref = firebase.firestore().collection('users/' + firebase.auth().currentUser.uid + '/cart').doc(itemId);
                batch.delete(ref);
            }
            const orderRef = firebase.firestore().collection('orders').doc();
            batch.set(orderRef, {
                user: this.props.user.uid,
                description: this.state.orderDescription,
                private: this.state.privateOrder,
                createdAt: firebase.firestore.Timestamp.now(),
                state: 'Erstellt',
                orderItems: itemRefs
            })
            batch.commit().then(() => {
                if (this.props.users[this.props.user.uid].role === 'coach') {
                    orderRef.update({state: 'Unterschrieben', updatedBy: this.props.user.uid});
                }
                this.setState({orderSummary: false, orderDescription: '', privateOrder: false});
            })
        } else {
            this.setState({orderSummary: false, orderDescription: '', privateOrder: false});
        }

    }
    ;

    orderDescriptionChangedHandler = (value) => {
        this.setState({orderDescription: value});
    }
    ;

    privateChangedHandler = (checked) => {
        this.setState({privateOrder: checked});
    }
    ;

    handleImport = (data) => {
        let error = '';
        data.forEach((item, index) => {
            let positionError = index + 1 + '. Position:\n';
            this.columns.forEach(column => {
                if (typeof column.validate === 'function' && (!column.validate(item[column.id]))) {
                    positionError += column.label + ' "' + item[column.id] + '" ist ungültig.\n';
                }
            });
            if (positionError !== index + 1 + '. Position:\n') {
                error += positionError;
            }
        });
        if (error !== '') {
            alert(error + 'Import wird abgebrochen');
            this.setState({import: false});
            return;
        }
        data.forEach(item => {
            firebase.firestore().collection('users/' + this.props.user.uid + '/cart').add({
                ...item,
                createdAt: firebase.firestore.Timestamp.now()
            });
        });
    }
    ;

    selectionChanged = itemKeys => {
        this.setState({selectedItems: itemKeys});
    }
    ;

    render() {
        const rows = {...this.state.orderItems};
        let subTotal = 0;
        for (let key in rows) {
            subTotal += this.state.orderItems[key].quantity * this.state.orderItems[key].pricePerUnit;
        }

        return (
            <React.Fragment>
                {this.state.orderSummary ?
                    <OrderSummary
                        open={this.state.orderSummary}
                        handleClose={this.closeOrderSummary}
                        orderPositions={rows}
                        orderDescription={this.state.orderDescription}
                        privateOrder={this.state.privateOrder}
                        orderDescriptionChanged={this.orderDescriptionChangedHandler}
                        privateChanged={this.privateChangedHandler}
                    /> : null
                }
                <Dialog open={this.state.import} fullWidth maxWidth={'lg'}
                        onClose={() => this.setState({import: false})}>
                    <Importer
                        processChunk={this.handleImport}
                        chunkSize={10000}
                        restartable={true}
                        onComplete={() => this.setState({import: false})}
                    >
                        {this.columns.map(column => (
                            <ImporterField key={column.id} name={column.id} label={column.label}/>

                        ))}
                    </Importer>
                </Dialog>
                <div>
                    <Button
                        variant="contained"
                        size='large'
                        color='primary'
                        style={{margin: '20px 20px 20px', width: 'auto', alignSelf: 'center'}}
                        startIcon={<AddIcon/>}
                        onClick={this.addItem}
                        disabled={Boolean(this.state.editItem)}
                    >
                        Position hinzufügen
                    </Button>
                    <Button
                        variant="contained"
                        size='large'
                        color='primary'
                        style={{margin: '20px 20px 20px', width: 'auto', alignSelf: 'center'}}
                        startIcon={<CloudUploadIcon/>}
                        onClick={() => this.setState({import: true})}
                        disabled={Boolean(this.state.editItem)}
                    >
                        CSV Import
                    </Button>
                </div>
                <MyTable
                    columns={this.columns}
                    rows={this.state.rows}
                    allowSelection
                    selectionChanged={this.selectionChanged}
                />
                <Menu anchorEl={this.state.menuAnchorElement} open={Boolean(this.state.menuAnchorElement)}
                      onClose={this.closeMenuHandler}>
                    <MenuItem
                        onClick={() => {
                            this.editItem(this.state.menuRowKey);
                            this.closeMenuHandler();
                        }}>
                        Bearbeiten
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            this.deleteItems([this.state.menuRowKey]);
                            this.closeMenuHandler();
                        }}>
                        Löschen
                    </MenuItem>
                </Menu>
                {Boolean(this.state.editItem) ?
                    <MyDialog
                        title={Boolean(this.state.editItemKey) ? 'Position bearbeiten' : 'Position hinzufügen'}
                        open={Boolean(this.state.editItem)}
                        cancelHandler={() => this.setState({editItemKey: null, editItem: null})}
                        saveHandler={this.saveItemChanges}
                        fields={this.columns}
                        data={this.state.editItem}
                    /> : null
                }

                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{border: 'none', width: '90%'}}/>
                            <TableCell style={{width: '10%'}}>
                                Total
                            </TableCell>
                            <TableCell align="right">
                                <b>{subTotal.toFixed(2)}</b>
                            </TableCell>
                            <TableCell
                                align="right"
                                style={{border: 'none', whiteSpace: 'nowrap', width: '0.01%', padding: '0px'}}
                            >
                                <IconButton style={{visibility: 'hidden'}}><MoreVertIcon/></IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div>
                    <Button
                        variant="contained"
                        size='large'
                        color='secondary'
                        disabled={Object.keys(this.state.orderItems).length === 0}
                        style={{margin: '20px 20px 20px', width: 'auto', alignSelf: 'center'}}
                        startIcon={<DeleteIcon/>}
                        onClick={() => {
                            this.deleteItems(this.state.selectedItems.length === 0 ?
                                Object.keys(this.state.orderItems) : this.state.selectedItems.slice(0));
                            this.setState({selectedItems: []});
                        }}
                    >
                        {this.state.selectedItems.length === 0 ? 'Alle Löschen' : 'Auswahl löschen'}
                    </Button>
                    <Button
                        variant="contained"
                        size='large'
                        color='primary'
                        disabled={Object.keys(this.state.orderItems).length === 0}
                        style={{margin: '20px 20px 20px', width: 'auto', alignSelf: 'center'}}
                        endIcon={<SendIcon/>}
                        onClick={this.order}
                    >
                        Alle Bestellen
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}

export default newOrder;