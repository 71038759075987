import React, {Component} from 'react';

import styles from './LoginForm.module.css';

import {TextField, Typography, Button} from '@material-ui/core';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

class loginForm extends Component {
    state = {
        loginState: 'login',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        password1: '',
        password2: ''
    };

    loginRegisterEnter = () => {
        if (this.state.loginState === 'login') {
            firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
                .then(userCredential => {
                    if(!userCredential.user.emailVerified){
                        alert("Bitte bestätige deine E-Mail Adresse und melde dich erneut an.")
                        firebase.auth().signOut();
                        this.setState({email: '', password: ''});
                    }
                })
                .catch(error => {
                    alert(error.message);
                });
        } else {
            if (this.state.password1 !== this.state.password2) {
                alert('Die Passwörter stimmen nicht überein!');
            } else if (this.state.password1.length < 6) {
                alert('Das Passwort muss midestens 6 Zeichen haben.');
            } else if (this.state.first_name === '' || this.state.last_name === '') {
                alert('Der Name ist ungültig.');
            } else if (!this.state.email.endsWith('@bbcag.ch')){
                alert('Du kannst dich nur mit einer @bbcag.ch E-Mail Adresse registrieren.');
            } else {
                firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password1)
                    .then(userCredential => {
                        userCredential.user.sendEmailVerification().then(() => {
                            firebase.firestore().collection('users').doc(userCredential.user.uid).set({
                                first_name: this.state.first_name,
                                last_name: this.state.last_name,
                                role: 'student'
                            }).then(() => {
                                alert("Bitte bestätige deine E-Mail Adresse und melde dich anschliessend an.")
                                firebase.auth().signOut();
                                this.setState({loginState: 'login', email: '', password: ''});
                            }).catch(error => {
                                alert(error.message);
                            });
                        }).catch(error => {
                            alert(error.message);
                        });
                    })
                    .catch(error => {
                        alert(error.message);
                    });
            }
        }
    };

    render() {

        return (
            <div className={styles.Form}
                 onKeyDown={(event) => {
                     if (event.key === 'Enter') {
                         this.loginRegisterEnter();
                     }
                 }}
            >
                <Typography variant="h2">
                    Bestellwesen Elektronik
                </Typography>
                <Typography variant="h6">
                    {this.state.loginState === 'login' ? 'Melde dich mit deiner E-Mail Adresse an.'
                        : 'Registriere dich mit deiner E-Mail Adresse.'}
                </Typography>
                <TextField
                    className={styles.Input}
                    value={this.state.email}
                    label="E-Mail"
                    required
                    type="email"
                    onChange={(event) => this.setState({email: event.target.value})}
                />
                {this.state.loginState === 'login' ?
                    <TextField
                        className={styles.Input}
                        value={this.state.password}
                        label="Passwort"
                        required
                        type="password"
                        onChange={(event) => this.setState({password: event.target.value})}
                    />
                    : null}
                {this.state.loginState === 'register' ?
                    <React.Fragment>
                        <TextField
                            className={styles.Input}
                            value={this.state.first_name}
                            label="Vorname"
                            required
                            onChange={(event) => this.setState({first_name: event.target.value})}
                        />
                        <TextField
                            className={styles.Input}
                            value={this.state.last_name}
                            label="Nachname"
                            required
                            onChange={(event) => this.setState({last_name: event.target.value})}
                        />
                        <TextField
                            className={styles.Input}
                            value={this.state.password1}
                            label="Passwort"
                            required
                            type="password"
                            onChange={(event) => this.setState({password1: event.target.value})}
                        />
                        <TextField
                            className={styles.Input}
                            value={this.state.password2}
                            label="Passwort wiederholen"
                            required
                            type="password"
                            onChange={(event) => this.setState({password2: event.target.value})}
                        />
                    </React.Fragment>
                    : null}

                <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '10px'}}
                    onClick={this.loginRegisterEnter}
                >
                    {this.state.loginState === 'login' ? 'Anmelden' : 'Registrieren'}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{margin: '10px'}}
                    onClick={() => this.setState(previousState => ({loginState: previousState.loginState === 'login' ? 'register' : 'login'}))}
                >
                    {this.state.loginState === 'register' ? 'Anmelden' : 'Registrieren'}
                </Button>

            </div>
        );
    }
}

export default loginForm;