import React, {Component} from 'react';

import {TableContainer, Table, TableBody, TableHead, TableRow, TableCell} from '@material-ui/core';

import MyRow from './MyRow/MyRow';

class myTable extends Component {
    state = {selectedRows: []};

    selectionChanged = (rowID, selectionState) => {
        this.setState(prevState => {
            if (selectionState) {
                prevState.selectedRows.push(rowID);
            } else {
                prevState.selectedRows = prevState.selectedRows.filter(row => row !== rowID);
            }
            return {selectedRows: prevState.selectedRows};
        }, () => {
            this.props.selectionChanged(this.state.selectedRows);
        });
    };

    static getDerivedStateFromProps(props, state) {
        state.selectedRows.forEach(selectedRow => {
            if(props.rows.filter(row => (row.id === selectedRow)).length === 0){
                state.selectedRows = state.selectedRows.filter(row => row !== selectedRow)
                props.selectionChanged(state.selectedRows)
                return {selectedRows: state.selectedRows};
            }
        })
        return null
    }


    render() {
        this.extendedContent = false;
        this.props.rows.forEach(row => {
            if (typeof row.extendedContent !== 'undefined') {
                this.extendedContent = true;
            }
        });

        return (
            <TableContainer style={{flexGrow: '1'}}>
                <Table style={this.props.style} stickyHeader>
                    <TableHead>
                        <TableRow>
                            {this.extendedContent || this.props.allowSelection ? <TableCell/> : null}
                            {this.props.columns.map(column => (
                                <TableCell key={column.id}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.rows.map(row => (
                            <MyRow
                                key={row.id}
                                row={row}
                                columns={this.props.columns}
                                extendedContent={this.extendedContent}
                                allowSelection={this.props.allowSelection}
                                selectionChanged={this.selectionChanged}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default myTable;